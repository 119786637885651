<template>
    <div>
        <div class="board-container">
            <archive-list-categories />

            <v-row>
                <v-col cols="6" md="4" v-for="board in boards" :key="board._id">
                    <div class="cursor-pointer" @click="$emit('click', board)">
                        <v-card elevation="0">
                            <v-img :aspect-ratio="380/240" :src="board.thumb">
                                <template #placeholder>
                                    <v-overlay absolute opacity="0.1">
                                        <v-icon x-large>mdi-image-off</v-icon>
                                    </v-overlay>
                                </template>
                            </v-img>
                        </v-card>
                        <div class="mt-10 mt-md-14">
                            <h3 v-if="BOARD_ARCHIVE_CATEGORIES[board.category]?.text" class="font-size-16 font-size-md-18 font-size-lg-24 primary--text text-truncate">{{ BOARD_ARCHIVE_CATEGORIES[board.category]?.text }}</h3>
                            <p class="page-text text-truncate">{{ board.subject }}</p>
                        </div>
                    </div>
                </v-col>
            </v-row>

            <div class="board-buttons">
                <slot v-if="!hideWriteButton" name="write-button"></slot>
            </div>

            <slot v-if="!hidePagination" name="pagination"></slot>
        </div>
    </div>
</template>

<script>
import { BOARD_ARCHIVE_CATEGORIES } from "@/assets/variables";
import ArchiveListCategories from "./archive-list-categories.vue";

export default {
    components: {
        ArchiveListCategories,
    },
    props: {
        summary: {
            type: Object,
            default() {
                return { totalCount: 0 };
            },
        },
        boards: { type: Array, default: null },

        hideWriteButton: { type: Boolean, default: false },
        hidePagination: { type: Boolean, default: false },
    },
    data: () => ({
        BOARD_ARCHIVE_CATEGORIES,
    }),
};
</script>

<style scoped>
</style>