var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "board-container"
  }, [_c('archive-list-categories'), _c('v-row', _vm._l(_vm.boards, function (board) {
    var _vm$BOARD_ARCHIVE_CAT, _vm$BOARD_ARCHIVE_CAT2;
    return _c('v-col', {
      key: board._id,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('div', {
      staticClass: "cursor-pointer",
      on: {
        "click": function ($event) {
          return _vm.$emit('click', board);
        }
      }
    }, [_c('v-card', {
      attrs: {
        "elevation": "0"
      }
    }, [_c('v-img', {
      attrs: {
        "aspect-ratio": 380 / 240,
        "src": board.thumb
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function () {
          return [_c('v-overlay', {
            attrs: {
              "absolute": "",
              "opacity": "0.1"
            }
          }, [_c('v-icon', {
            attrs: {
              "x-large": ""
            }
          }, [_vm._v("mdi-image-off")])], 1)];
        },
        proxy: true
      }], null, true)
    })], 1), _c('div', {
      staticClass: "mt-10 mt-md-14"
    }, [(_vm$BOARD_ARCHIVE_CAT = _vm.BOARD_ARCHIVE_CATEGORIES[board.category]) !== null && _vm$BOARD_ARCHIVE_CAT !== void 0 && _vm$BOARD_ARCHIVE_CAT.text ? _c('h3', {
      staticClass: "font-size-16 font-size-md-18 font-size-lg-24 primary--text text-truncate"
    }, [_vm._v(_vm._s((_vm$BOARD_ARCHIVE_CAT2 = _vm.BOARD_ARCHIVE_CATEGORIES[board.category]) === null || _vm$BOARD_ARCHIVE_CAT2 === void 0 ? void 0 : _vm$BOARD_ARCHIVE_CAT2.text))]) : _vm._e(), _c('p', {
      staticClass: "page-text text-truncate"
    }, [_vm._v(_vm._s(board.subject))])])], 1)]);
  }), 1), _c('div', {
    staticClass: "board-buttons"
  }, [!_vm.hideWriteButton ? _vm._t("write-button") : _vm._e()], 2), !_vm.hidePagination ? _vm._t("pagination") : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }